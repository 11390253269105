<template>
  <section>
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="isEmpty"
    >
      <EmptyListAlert
        buttonLabel="add_new_alarm"
        importLabel="import_alarms"
        :hideImport="hideImport()"
        :isInstance="connector && connector.base_model_id != null"
        @import="importAlarms()"
        :newItemPath="newItemPath"
        :disabled="isModelBased"
      />
    </div>
    <template v-else>
      <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
        <SearchableTable
          class="searchable-table"
          v-if="!isEmpty"
          :items="list"
          :fields="fields"
          :commands="commands"
          :multiColumnOrder="false"
          :clientSort="true"
          :pagination="pagination"
          :maxResult="maxResult"
          :deepSearch="false"
          :multiSelection="multiSelection"
          :disabled="cloneEnabled"
          :sortDef="{
            column: cloneEnabled || !editable ? 'name' : 'draft_name',
            asc: true
          }"
          @select="onSelect"
          @command="onCommand"
          @loadNextPage="onLoadNextPage"
          @multiColumnSort="onMultiColumnSort"
          @nItems="maxResult = $event"
          ref="stbl"
        >
          <template #extraButtons>
            <span
              id="export"
              class="btn btn-default"
              :disabled="list && list.length == 0"
              @click.stop.prevent="downloadCSV(connector)"
              :title="$t('hints.button_export')"
            >
              <i class="fa fa-file-excel-o" />
            </span>
            <span
              v-if="multiSelection.key"
              id="mass_remove"
              class="btn btn-default"
              :disabled="!massRemoveEnabled"
              @click="massRemove"
              :title="$t('mass_remove')"
            >
              <i class="fa fa-trash-o" />
            </span>
          </template>
          <template #statistics="{ itemsInPage }">
            <ResourceStatistics
              resource="alarm"
              :total="maxResult"
              :showing="itemsInPage"
              icon="fa fa-bell"
            />
          </template>
          <template #level="entry">
            <AlarmLevelDisplay :alarm="entry.item" />
          </template>
          <template v-slot:toggle="entry">
            <ResourceToggle v-if="entry.item.etag" :item="entry.item" />
          </template>
          <!-- BEGIN editable columns -->

          <!-- begin draft_name -->
          <template v-slot:draft_name="entry" v-if="!cloneEnabled && editable">
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(entry.item, 'name')
                }"
              >
                <input
                  type="text"
                  class="form-control text-left"
                  :value="draftValue(entry.item, 'name')"
                  @input="draftValue(entry.item, 'name', $event)"
                  @keydown="onKeyDown"
                  @contextmenu.prevent.stop
                  :disabled="!draftEnabled(entry.item, 'name')"
                />
              </div>
            </div>
          </template>
          <!-- end draft_name -->

          <!-- begin draft_alarm_trigger_condition -->
          <template
            v-slot:draft_alarm_trigger_condition="entry"
            v-if="!cloneEnabled && editable"
          >
            <div class="column-content" @click.stop>
              <div class="form-group form-group-sm">
                <select
                  :key="entry.item.id"
                  class="form-control trigger-condition"
                  :value="draftValue(entry.item, 'alarm_trigger_condition')"
                  @input="
                    draftValue(entry.item, 'alarm_trigger_condition', $event)
                  "
                  :disabled="
                    !draftEnabled(entry.item, 'alarm_trigger_condition')
                  "
                >
                  <option
                    :value="o.id"
                    :key="o.id"
                    v-for="o in alarmTriggerConditions(entry.item)"
                  >
                    {{ o.comparison_operator }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <!-- end draft_alarm_trigger_condition -->

          <!-- begin draft_limit -->
          <template v-slot:draft_limit="entry" v-if="!cloneEnabled && editable">
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(entry.item, 'limit')
                }"
                :title="$t(limitTootltipText(entry.item))"
              >
                <input
                  type="text"
                  class="form-control text-right"
                  :value="draftValue(entry.item, 'limit')"
                  @input="draftValue(entry.item, 'limit', $event)"
                  :disabled="!draftEnabled(entry.item, 'limit')"
                />
              </div>
            </div>
          </template>
          <!-- end draft_limit -->

          <!-- begin draft_level -->
          <template v-slot:draft_level="entry" v-if="!cloneEnabled && editable">
            <div class="column-content" @click.stop>
              <div class="form-group form-group-sm">
                <select
                  class="form-control level"
                  :value="draftValue(entry.item, 'level')"
                  @input="draftValue(entry.item, 'level', $event)"
                  :disabled="!draftEnabled(entry.item, 'level')"
                >
                  <option
                    :value="o.label.value"
                    :key="o.label.value"
                    v-for="o in levelOptions"
                  >
                    {{ parseLevel(o.label.value) }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <!-- end draft_level -->

          <!-- begin data name -->
          <template v-slot:data="entry">
            <div class="column-content">
              <div class="form-group form-group-sm">
                <input
                  type="text"
                  class="form-control"
                  :value="entry.item.data.name"
                  :disabled="true"
                  style="padding-left: 5px"
                />
              </div>
            </div>
          </template>
          <!-- end draft_limit -->
          <!-- END editable columns -->
        </SearchableTable>
        <div class="table-form-footer" v-if="!cloneEnabled && editable">
          <span
            class="btn btn-default pull-left"
            :disabled="!draftDirty()"
            @click="draftReset()"
          >
            <i class="fa fa-undo"></i> {{ $tc("restore", 1) }}
          </span>
          <span
            class="btn btn-primary pull-right"
            :disabled="isSaving || !draftDirty() || draftError()"
            @click.stop.prevent="save"
          >
            <i :class="isSaving ? 'fa fa-refresh fa-spin' : 'fa fa-save'"></i>
            {{ $tc(isSaving ? "saving" : "save", 1) }}
          </span>
        </div>
      </div>
    </template>

    <ModalImportFile
      :connectorId="connector_id"
      :deviceId="device_id"
      :dataId="data_id"
      :entity="importEntity"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>

<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import AlarmLevelDisplay from "@/components/alarm-level-display.vue";
import AlarmService from "@/services/alarm.js";
import DataService from "@/services/data.js";
import ModalImportFile from "@/components/modal-import-file.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";

import { InlineFormEditor } from "@/components/searchable-table.vue";
import { isSingleDevice } from "@/services/connector.js";
import { validNumber } from "@/plugins/utils.js";
import {
  strParser,
  onKeyDown
} from "@/components/registration/form-common-fields-inline.vue";
export default {
  name: "DashboardEditAlarmPicker",
  components: {
    SearchableTable,
    EmptyListAlert,
    ResourceStatistics,
    AlarmLevelDisplay,
    ModalImportFile,
    ResourceToggle
  },
  extends: DashboardEditPickerBase,
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: false,
      default: 0
    },
    data_id: {
      type: Number,
      required: false,
      default: 0
    },
    dataList: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    showEmptyPanel: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      entity: "alarm",
      items: null,
      isSaving: false,
      importEntity: ""
    };
  },
  computed: {
    conditions() {
      return this.$root.config.references.alarm_trigger_conditions || [];
    },
    fields() {
      let fields = [];
      fields.push({
        name: "id",
        title: "id",
        visible: false
      });

      if (!this.data_id) {
        fields.push({
          name: "device_id",
          title: "device",
          parser: (item) =>
            this.deviceById(item.device_id)?.name || item.device_id
        });
      }

      //== /name
      fields.push({
        name: "name",
        title: "alarm",
        visible:
          this.cloneEnabled || !this.editable || this?.connector?.base_model_id,
        parser: (item) => {
          return item.name;
        }
      });
      fields.push({
        name: "draft_name",
        title: "name",
        visible:
          !this.cloneEnabled &&
          this.editable &&
          !this?.connector?.base_model_id,
        style: {
          position: "relative",
          "text-align": "center",
          "min-width": "200px"
        },
        parser: (item) => {
          return item?._draft?.fields?.name?.value ?? "";
        }
      });
      //== \name

      fields.push({
        name: "data",
        title: "data",
        class: "form-control",
        parser: (item) => {
          return item.data.name;
        }
      });

      //== /alarm_trigger_condition
      fields.push({
        name: "alarm_trigger_condition",
        title: "alarm_trigger_condition",
        visible: this.cloneEnabled || !this.editable,
        parser: (item) => {
          return item.alarm_trigger_condition?.comparison_operator;
        }
      });
      fields.push({
        name: "draft_alarm_trigger_condition",
        title: "alarm_trigger_condition",
        visible: !this.cloneEnabled && this.editable,
        style: {
          position: "relative",
          "text-align": "center",
          "min-width": "60px",
          "max-width": "80px"
        },
        parser: (item) => {
          let vlr = item?._draft?.fields?.alarm_trigger_condition?.value ?? "";
          if (vlr !== "") {
            vlr = `${vlr} ${
              this.conditions.find(({ id }) => parseInt(id) == parseInt(vlr))
                ?.comparison_operator ?? ""
            }`;
          }
          return vlr;
        }
      });
      //== \alarm_trigger_condition

      //== /limit
      fields.push({
        name: "limit",
        title: "limit",
        visible: this.cloneEnabled || !this.editable,
        parser: (item) => {
          return item.limit;
        }
      });
      fields.push({
        name: "draft_limit",
        title: "limit",
        visible: !this.cloneEnabled && this.editable,
        style: {
          position: "relative",
          "text-align": "center",
          "min-width": "60px",
          "max-width": "80px"
        },
        parser: (item) => {
          const v = parseFloat(item?._draft?.fields?.limit.value ?? "");
          return isNaN(v) ? item?._draft?.fields?.limit.value ?? "" : v;
        }
      });
      //== \limit

      //== /level
      fields.push({
        name: "level",
        title: this.$tc("level", 1),
        visible: this.cloneEnabled || !this.editable,
        parser: (item) => this.parseLevel(item?.level)
      });
      fields.push({
        name: "draft_level",
        title: this.$tc("level", 1),
        visible: !this.cloneEnabled && this.editable,
        parser: (item) => {
          const vlr = item?._draft?.fields?.level?.value ?? "";
          return vlr !== "" ? this.parseLevel(vlr) : vlr;
        }
      });
      //== \level

      fields.push({
        name: "acknowledgment_enabled",
        title: "acknowledgement",
        parser: (item) => {
          return this.$tc(item.acknowledgment_enabled ? "enabled" : "disabled");
        }
      });
      fields.push({
        name: "toggle",
        title: "enabled",
        parser: (item) => {
          return `${item.enabled ? 0 : 1} ${this.$t(
            item.enabled ? "yes" : "no"
          )}`;
        },
        style() {
          return { "text-align": "center" };
        }
      });
      return fields;
    },
    commands() {
      if (!this) return [];
      let commands = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            if (
              this.isFreePlan ||
              (this.device_id &&
                this.deviceById(this.device_id)?.reference_device_id)
            )
              return false;

            let data = (this.dataList || []).find(
              ({ id }) => id == this.data_id
            );
            let connector =
              (data ? data?.device?.connector : this.connector) || null;
            return (
              this.$can("manage", "AlarmeCadastro") &&
              !this.cloneEnabled &&
              (!connector || !connector.base_model_id)
            );
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: () => {
            return (
              !this.nSelected &&
              this.$can("manage", "AlarmeCadastro") &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: (item) => {
            let device =
              (item?.data?.device_id &&
                this.deviceById(item?.data?.device_id)) ||
              (item?.device_id && this.deviceById(item?.device_id)) ||
              null;
            if (!device) {
              device =
                (this.dataList || []).find(({ id }) => id == item.data_id)
                  ?.device || null;
            }
            let connector =
              (device && device?.connector) ||
              this.connectorById(device?.connector_id) ||
              null;
            return (
              connector &&
              !this.nSelected &&
              !connector.base_model_id &&
              this.$can("manage", "AlarmeCadastro") &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          enabled: (item) => {
            if (this.nSelected) return false;
            return this.removeEnabled(item);
          }
        }
      ];
      if (this.cloneEnabled) return commands;
      if (this.$can("manage", "EquipamentoCadastro"))
        commands[0].commands.push({
          name: "newConnector",
          title: "new_connector",
          icon: "fa fa-plus"
        });
      if (!(this.$store.getters["dashboard/connectorList"] || []).length)
        return commands;
      if (this.$can("manage", "EstacaoCadastro"))
        commands[0].commands.push({
          name: "newDevice",
          title: "new_device",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro"))
        commands[0].commands.push({
          name: "newData",
          title: "new_data",
          icon: "fa fa-plus"
        });
      if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
        commands[0].commands.push({
          name: "newAlarm",
          title: "new_alarm",
          icon: "fa fa-plus"
        });
      if (
        !this.isFreePlan &&
        this.$can("manage", "AlarmeCadastro") &&
        !this.hideImport()
      )
        commands[0].commands.push({
          name: "importAlarms",
          title: "import_alarms",
          icon: "fa fa-plus"
        });

      return commands;
    },
    list() {
      return (this.dataList || []).length
        ? (this.items || []).filter((alarm) =>
            (this.dataList || []).some(({ id }) => id == alarm.data_id)
          )
        : this.items;
    },
    newItemPath() {
      return `/dashboard/edit/connector/${this.connector_id || 0}/device/${
        this.device_id || 0
      }/data/${this.data_id || 0}/alarm/0`;
    },
    connector() {
      return this.connectorById(this.connector_id) || null;
    },
    connectorList() {
      try {
        return this.$store.getters["dashboard/connectorList"];
      } catch {
        return [];
      }
    },
    levelOptions() {
      return this?.contract?.portal_data?.alarm_level_options || [];
    },
    massRemoveEnabled() {
      if (!this.nSelected) return false;
      let self = this;
      let removeEnabledList = this.items.filter((item) => {
        return (
          self.multiSelection.values.indexOf(item.id) >= 0 &&
          self.removeEnabled(item)
        );
      });
      return removeEnabledList.length == this.nSelected;
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    memoryTypeList() {
      return this.references.data_memory_types || [];
    }
  },
  watch: {
    parent: {
      handler(n) {
        if (n) {
          let data = n.id
            ? n
            : this.getResourceById("data", this.data_id) || {};
          let device = n?.device
            ? n.device
            : this.getResourceById("device", this.device_id) || {};
          let connector = n?.device?.connector
            ? n?.device?.connector
            : this.getResourceById("connector", this.connector_id) || {};

          let items = [
            {
              name: "connector_plural",
              url: "/dashboard/edit/connector"
            },
            {
              name: connector?.name,
              url: `/dashboard/edit/connector/${connector?.id}`
            }
          ];
          if (!isSingleDevice(n.connector)) {
            items = items.concat([
              {
                name: "device_plural",
                url: `/dashboard/edit/connector/${connector?.id}/device`
              },
              {
                name: device?.name,
                url: `/dashboard/edit/connector/${connector?.id}/device/${device?.id}`
              }
            ]);
          }
          items = items.concat([
            {
              name: "data_plural",
              url: `/dashboard/edit/connector/${connector?.id}/device/${device?.id}/data`
            },
            {
              name: data?.name || n?.name,
              url: `/dashboard/edit/connector/${connector?.id}/device/${
                device?.id
              }/data/${data?.id || this.data_id}`
            },
            { name: "alarm_plural", url: `` }
          ]);

          this.$set(this, "nav", {
            previous: `/dashboard/edit/connector/`,
            items: items
          });
        }
      },
      deep: true
    },
    connector_id(n, o) {
      if (o && n && n != o) {
        this.items = [];
        this.fetchItems();
      }
    }
  },
  methods: {
    removeEnabled(item) {
      let device =
        (item?.data?.device_id && this.deviceById(item?.data?.device_id)) ||
        (item?.device_id && this.deviceById(item?.device_id)) ||
        null;
      if (!device) {
        device =
          (this.dataList || []).find(({ id }) => id == item.data_id)?.device ||
          null;
      }
      let connector =
        (device && device?.connector) ||
        this.connectorById(device?.connector_id) ||
        null;
      return (
        connector &&
        !connector.base_model_id &&
        this.$can("manage", "AlarmeCadastro") &&
        !this.cloneEnabled
      );
    },
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "alarm",
          this.$tc("n_records_selected", item.length, {
            amount: item.length
          }),
          "you_wont_be_able_to_revert_this"
        );
      }

      const isConnectorBaseModel = this.connectorList.find(
        (connector) => connector.id == item.connector_id
      ).base_model;
      if (isConnectorBaseModel) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("alarm");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (this.isModel) {
        this.$router.push(
          `/dashboard/edit/connector/${this.connector_id}/device/${this.device_id}/data/${this.data_id}/alarm/0`
        );
        return;
      }
      this.validateResourceQuota(
        "alarm_plural",
        this.contract.maximum_alarms,
        this.contract.registered_alarms
      ).then((resp) => {
        if (resp == "proceed") {
          this.$router.push(
            `/dashboard/edit/connector/${this.connector_id}/device/${this.device_id}/data/${this.data_id}/alarm/0`
          );
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    edit(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${
          this.device_id || command?.target?.device_id || 0
        }/data/${this.data_id || command?.target?.data_id || 0}/alarm/${
          command.target.id
        }`
      );
    },
    remove(command) {
      let config = {
        item: command.target,
        type: "alarm",
        resource_key: "alarm_id",
        rule: "AlarmeCadastro",
        service: this.service
      };

      let callback = () => {};

      this.validateAndRemove(config, callback);
    },
    massRemove(command) {
      let self = this;
      if (this.multiSelection.values.length == 0) {
        return;
      }

      let config = {
        items: this.multiSelection.values,
        type: "alarm",
        resource_key: "alarm_id",
        rule: "AlarmeCadastro",
        service: this.service
      };

      // Armazenar os dispositivos selecionados que são Dispositivo de Referência
      let reference_ids = (this.items || [])
        .filter((item) => {
          if (this.multiSelection.values.indexOf(item.id) == -1) return false;

          let alarm_data =
            (this.dataList || []).find(
              (item_data) => item_data.id == item.data_id
            ) ?? {};

          if (!alarm_data?.device?.is_reference) return false;
          if (!alarm_data?.device?.data_collector_device_id) return false;
          return true;
        })
        .map((item) => {
          return item.reference_id;
        });
      const relatedAlarm = (reference_id) => {
        return (this.items || []).filter((item) => {
          let item_data =
            (this.dataList || []).find((data) => data.id == item.data_id) ?? {};
          return (
            item?.reference_id == reference_id && // same alarm
            item_data?.device?.data_collector_device_id && // a virtual device?
            item_data?.device?.reference_device_id
          ); // based on device model?
        });
      };
      let related_items = [];
      reference_ids.forEach((reference_id) => {
        related_items = related_items.concat(relatedAlarm(reference_id));
      });

      let callback = () => {
        // Remover da lista também os Dispositivo de Referência
        if (related_items.length) {
          let items = this.items.filter(
            (item) =>
              !related_items.find((related_item) => related_item.id == item.id)
          );
          this.$set(this, "items", items);
          this.$store.dispatch(
            "dashboard/removeResources",
            related_items.map(({ id }) => ({ alarm_id: id }))
          );
        }

        self.multiSelection.values = [];
      };
      this.validateAndMassRemove(config, callback);
    },
    clone(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${
          this.device_id || command?.target?.device_id || 0
        }/data/${this.data_id || command?.target?.data_id || 0}/alarm/${
          command.target.id
        }?a=c`
      );
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();

      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.fetchItems();
      }
    },
    // exportData() {
    //   let search_input = document.querySelector(
    //     '.search-column input[type="text"]'
    //   );
    //   let ids = this?.multiSelection?.values ? this?.multiSelection?.values + '' : '';
    //   let params =
    //     `contract_id=${this.contract.id}&` +
    //     `connector_id=${this.connector_id}&` +
    //     `device_id=${this.device_id}&` +
    //     `data_id=${this.data_id}&` +
    //     `ids=${ids}&` +
    //     `search=${search_input.value}`;
    //   this.service.export(params, `alarm-connector-${this.connector.name}.csv`);
    // },
    hideImport() {
      return (
        !this.$can("manage", "AlarmeCadastro") ||
        !this.connector ||
        this.connector.base_model_id != null
      );
    },
    fetchItems(URL) {
      let url = URL || "";
      let query = null;
      if (!url) {
        query = {};
        query.contract_id = this.contract.id;
        if (this.connector_id) query.connector_id = this.connector_id;
        if (this.device_id) query.device_id = this.device_id;
        if (this.data_id) query.data_id = this.data_id;
      }
      this.fetch(query, url).then(() => {
        // parse them
        let na, nb;
        this.items.sort((a, b) => {
          na = `${a?.device_id || ""}_${a?.data_id || ""}_${a.name}`;
          nb = `${b?.device_id || ""}_${b?.data_id || ""}_${b.name}`;
          return na > nb ? 1 : nb > na ? -1 : 0;
        });
        this.$store.dispatch("dashboard/addResource", {
          resource: "alarm",
          list: this.items,
          forceUpdate: true
        });
        if (this.items.length && this.items[0].data) {
          this.$set(this, "parent", this.items[0].data);
        } else {
          if (this.data_id) {
            let srv = new DataService();
            srv.get(this.data_id).then((result) => {
              if (result) {
                this.$set(this, "parent", result);
              }
            });
          }
        }
      });
    },
    parseLevel(level) {
      let label = "";
      if (level) {
        label = (
          this?.contract?.portal_data?.alarm_level_options ||
          [] ||
          []
        ).some((d) => d?.label?.value == level)
          ? this.$t(`alarm_level.${level}`)
          : level;
        label = `alarm_level.${level}` == label ? level : label;
      }
      return label || "";
    },
    parseItems(items) {
      if (!this.editable) return items;
      if (this.inlineFormEditor) return items;
      let self = this;
      const validateItems = (items, currentItem) => {
        (items || []).forEach((item) => {
          // validate the ones within same data
          if (
            item?.data?.id &&
            parseInt(item?.data?.id) != parseInt(currentItem?.data?.id)
          )
            return;
          if (
            item?.data_id &&
            parseInt(item?.data_id) != parseInt(currentItem?.data_id)
          )
            return;
          // validate the ones within same device
          if (
            item?.data.device?.id &&
            parseInt(item?.data?.device?.id) !=
              parseInt(currentItem?.data?.device?.id)
          )
            return;
          if (
            item?.device_id &&
            parseInt(item?.device_id) != parseInt(currentItem?.device_id)
          )
            return;

          // name
          item._draft.fields.name.error = null;
          if (
            parseInt(item?.id) != parseInt(currentItem?.id) &&
            currentItem?._draft.fields?.name?.value !== "" &&
            currentItem?._draft.fields?.name?.value ==
              item?._draft.fields?.name?.value
          ) {
            currentItem._draft.fields.name.error = "duplicated";
            item._draft.fields.name.error = "duplicated";
          }
        });
      };
      this.inlineFormEditor = new InlineFormEditor(items, (item) => ({
        name: {
          item_attr: "name",
          value: `${item?.name}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
          },
          save: (item) => {
            item._draft.fields.name.previous = item._draft?.fields.name.value;
            item.name = item._draft.fields.name.value;
          }
        },
        alarm_trigger_condition: {
          item_attr: "alarm_trigger_condition_id",
          value: `${item?.alarm_trigger_condition?.id ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
          },
          save: (item) => {
            item._draft.fields.alarm_trigger_condition.previous =
              item._draft?.fields.alarm_trigger_condition.value;
            item.alarm_trigger_condition.id = parseInt(
              item._draft.fields.alarm_trigger_condition.value
            );
          }
        },
        limit: {
          item_attr: "limit",
          value: `${item?.limit ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            item._draft.fields.limit.error = "";

            validateItems(items, item);

            if (item._draft.fields.limit.value == "") {
              item._draft.fields.limit.error = "invalid";
            } else if (
              this.isFloat(item) &&
              !validNumber(item._draft.fields.limit.value)
            ) {
              item._draft.fields.limit.error = "invalid";
            }
          },
          save: (item) => {
            item._draft.fields.limit.previous = item._draft?.fields.limit.value;
            item.limit = parseInt(item._draft.fields.limit.value);
          }
        },
        level: {
          item_attr: "level",
          value: `${item?.level ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
          },
          save: (item) => {
            item._draft.fields.level.previous = item._draft?.fields.level.value;
            item.level = parseInt(item._draft.fields.level.value);
          }
        }
      }));
      return items;
    },
    draftValue(item, attr, $event) {
      if (!this.inlineFormEditor) return;
      return this.inlineFormEditor.prop(
        item,
        attr,
        $event ? $event.target.value : undefined
      );
    },
    draftReset(item, attr, $event) {
      if (!this.inlineFormEditor || ($event && !this.draftEnabled(item, attr)))
        return;
      this.inlineFormEditor.reset(item, attr);
      let $el =
        $event && $event.target.parentElement.getElementsByTagName("input")[0];
      if ($el) {
        $el.focus();
        $el.select();
      }
    },
    draftError(item, attr) {
      if (!this.inlineFormEditor) return;
      return this.inlineFormEditor.error(item, attr);
    },
    draftDirty(item, attr) {
      if (!this.inlineFormEditor) return false;
      return this.inlineFormEditor.isDirty(item, attr);
    },
    draftEnabled(item, attr) {
      return true;
    },
    save() {
      if (!this.draftDirty() || this.draftError()) return;
      let payload = (this?.items || [])
        ?.filter((i) => (i.id && i.etag && this.draftDirty(i)) || false)
        .map((i) => {
          let entry = null;
          for (var p in i._draft?.fields) {
            if (this.draftEnabled(i, p) && this.draftDirty(i, p)) {
              entry = entry || { id: i.id, etag: i.etag };
              entry[i._draft?.fields[p].item_attr] = i._draft.fields[p].value;
            }
          }
          return entry;
        })
        .filter((i) => i != null);
      if (!payload.length) return;
      this.isSaving = true;
      this.service.save(payload).then((ret) => {
        this.isSaving = false;
        if (ret && ret.length == payload.length && ret[0].id) {
          if (this.validateSaveResponse(ret[0])) {
            let txt = this.$t("you_have_saved_n_items", {
              count: ret.length
            });
            this.alert = {
              title: this.$t("save"),
              text: txt,
              html: txt,
              type: "success"
            };

            (ret || []).forEach((item) => {
              let localItem =
                this.items.find(({ id }) => id == item.id) || null;
              let sentItem = payload.find(({ id }) => id == item.id) || null;
              if (localItem && sentItem) {
                localItem.etag = item.etag;
                for (var p in localItem._draft?.fields) {
                  // localItem._draft.fields[p].previous =
                  //   localItem._draft?.fields[p].value;
                  localItem._draft.fields[p].save(localItem);
                }
              }
            });

            this.$store.dispatch("dashboard/setAlarmValue", this.items);
            this.$emit("alarm", this.items);
            this.showAlert();
            return;
          }
        }
        this.validateSaveResponse(ret);
        this.showAlert();
      });
    },
    alarmData(alarm) {
      return (
        (this.dataList || []).find(({ id }) => id == alarm.data_id) ||
        this.parent
      );
    },
    format(alarm, value) {
      let data = this.alarmData(alarm);
      if (!data) return "";
      var fmt = (
        this.$root.config.references.data_value_format_types || []
      ).find(({ id }) => id == data?.value_format_type?.id);
      if (!fmt || fmt.format_mask == "text_list") {
        return value;
      } else {
        if (fmt.format_mask == "duration") {
          return moment
            .duration(parseInt(value), data.unity_label)
            .format(data.custom_format, { trim: false });
        } else {
          return this.$utils.sprintf(
            data.custom_format || fmt.format_mask,
            isNaN(parseFloat(value)) ? value : parseFloat(value)
          );
        }
      }
    },
    deviceById(deviceId) {
      return (
        (deviceId &&
          this.$store.getters["dashboard/deviceList"].find(
            ({ id }) => parseInt(id) == parseInt(deviceId)
          )) ||
        null
      );
    },
    connectorById(connectorId) {
      return (
        (connectorId &&
          this.$store.getters["dashboard/connectorList"].find(
            ({ id }) => parseInt(id) == parseInt(connectorId)
          )) ||
        null
      );
    },
    usesReference(item) {
      return (
        !item?.reference_device_id ||
        parseInt(item?.reference_device_id) == parseInt(item?.device_id)
      );
    },
    memoryTypeInterfaceLabel(memoryTypeName) {
      if (!memoryTypeName) return "";
      let iface = (this?.memoryTypeList || []).find((i) => {
        return i.name == memoryTypeName;
      });
      return (iface?.basic_type?.label || "").toLowerCase();
    },
    isString(alarm) {
      return (
        this.memoryTypeInterfaceLabel(alarm?.data?.memory_type?.name) ==
        "string"
      );
    },
    isFloat(alarm) {
      return (
        this.memoryTypeInterfaceLabel(alarm?.data?.memory_type?.name) == "float"
      );
    },
    alarmTriggerConditions(alarm) {
      return (this?.conditions || [])
        .filter(
          ({ comparison_operator }) =>
            !this.isString(alarm) ||
            comparison_operator == "==" ||
            comparison_operator == "!="
        )
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    },
    limitTootltipText(alarm) {
      if (this.isString(alarm)) {
        return "hints.alarm_limit_string";
      } else if (this.isFloat(alarm)) {
        return "hints.alarm_limit_float";
      } else {
        return "hints.alarm_limit_number";
      }
    },
    strParser: strParser,
    onKeyDown: onKeyDown
  },
  beforeCreate() {
    this.service = new AlarmService();
    this.$emit("rule", "AlarmeAcesso");
  },
  created() {
    this.title = "alarm_list";
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 0 0 30px 0;
  padding: 0 15px 50px 15px;
  overflow-x: auto;
}
.searchable-table {
  padding-bottom: 100px;
}

.column-content {
  pointer-events: auto;
}

.column-content > .form-group {
  padding: 0;
  margin: 0;
  min-width: 70px;
}

.column-content > .form-group .form-control {
  padding: 0 5px 0 0;
}
.table-form-footer {
  margin: -50px -10px 0 -10px;
  padding: 20px;
  border-top: 1px solid #adadad;
}

.trigger-condition {
  text-align: center;
}

.level {
  text-align: center;
}

@media (max-width: 768px) {
  #export {
    margin-left: -32px;
  }
}
</style>
